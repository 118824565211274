//import Components
import ReactLoading from "react-loading";

export default function Loadingscreen ({...props}) {
    return (
        <div className="App" style={{background: "#000000", minHeight: "100vh"}} {...props}>
            <div className="page_loading" style={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                <ReactLoading delay={500} className={`page-loading`} type={"spinningBubbles"} color={"#efefef"} height={'10%'} width={'10%'}/>
            </div>
        </div>
    )
}
