import {lazy} from "react";

//page import
const Home = lazy(() => import("./pages/Home/index.jsx"));
const PageNotFound = lazy(() => import("./pages/Errors/404.jsx"));
const BadRequest = lazy(() => import("./pages/Errors/400.jsx"));
const About = lazy(() => import("./pages/About/index.jsx"));
const ProfessionalDevelopment = lazy(() => import("./pages/ProfessionalDevelopment/Resources.jsx"));
const AboutSupportNet = lazy(() => import("./pages/About/subpages/SupportTheNet/index.jsx"));
const AboutJahlon = lazy(() => import("./pages/About/subpages/jahlon/index.jsx"));
const AboutLatestUpdate = lazy(() => import("./pages/About/subpages/LatestUpdate/index.jsx"));
const CCPostForWhat = lazy(() => import("./pages/ContentCreation/ForWhat.jsx"));



export const routes = [
    //static pages
    {
        link: "/",
        label: "Home",
        showInNav: false,
        exact: true,
        elem: <Home/>
    },

    {
        link: "/about",
        label: "About",
        showInNav: true,
        exact: true,
        elem: <About/>,
        subMenu: [
            {
                link: "/about/updates",
                label: "Latest updates",
                showInNav: true,
                elem: <AboutLatestUpdate/>,
            },
            {
                link: "/about/supportNetwork",
                label: "Support the network",
                showInNav: true,
                elem: <AboutSupportNet/>
            },
            {
                link: "/about/jahlon",
                label: "Jahlon",
                showInNav: true,
                elem: <AboutJahlon/>
            }
        ]
    },

    {
        label: "Project",
        showInNav: true,
        exact: true,
        subMenu: [
            {
                link: "https://www.ashes101.com",
                label: "Ashes 101",
                showInNav: true,
                external: true
            },
            {
                link: "https://www.archeage101.com",
                label: "Archeage 101",
                showInNav: true,
                external: true
            },
            {
                link: "https://www.pgnmusic.com",
                label: "PGN Music",
                showInNav: true,
                external: true
            },
            {
                link: "https://www.youtube.com/@ParadoxGamingNetwork",
                label: "Paradox Gaming Network Youtube",
                showInNav: true,
                external: true
            },
            {
                link: "https://www.twitch.tv/paradox_gaming_network",
                label: "Paradox Gaming Network Twitch",
                showInNav: true,
                external: true
            }
        ]
    },

    //content creation
    {
        label: "Creator Guide",
        showInNav: true,
        subMenu: [
            {
                link: "/cc/forwhat",
                label: "For what",
                showInNav: true,
                elem: <CCPostForWhat/>
            }
        ]
    },

    //blogs
    {
        link: "/profestiondevelopment",
        label: "Professional Development",
        showInNav: true,
        elem: <ProfessionalDevelopment/>
    },


    //error pages
    {
        link: "/404",
        showInNav: false,
        label: "Page not found",
        elem: <PageNotFound/>
    },
    {
        link: "/400",
        showInNav: false,
        label: "Bad request",
        elem: <BadRequest/>
    }
]
