//import Components
import SocialItem from "../../components/socials/SocialItem/SocialItem.jsx";
import {Col, Row} from "reactstrap";
import FooterLinks from "./FooterLinks.jsx";


export default function Footer({...props}) {
    return (
        <>
            <footer className={`footer`} {...props}>
                <Row className={"footer_container"}>
                    <Col xs={12} sm={6} md={6} lg={3} className={"footer_left"}>
                        <span>©PARADOX GAMING NETWORK</span>
                    </Col>
                    <Col xs={12} md={12} lg={6} className={"footer_center"}>
                        <div className={"footer_socials row"}>
                            <SocialItem social={"youtube"} href={"https://discord.gg/paradox_gaming_network"}/>
                            <SocialItem social={"twitch"} href={"https://www.twitch.tv/paradox_gaming_network"}/>
                            <SocialItem social={"spotify"} href={"https://open.spotify.com/artist/4qZWTa2bhoPr2AOsANBazT?si=U-fmGjxQSquuZG-CEVvA0w"}/>
                            <SocialItem social={"facebook"} href={"https://www.facebook.com/paradoxgamingnet"}/>
                            <SocialItem social={"discord"} href={"https://discord.gg/QxV6KpVycu"}/>
                            <SocialItem social={"twitter"} href={"https://twitter.com/Paradox_GameNet"}/>
                            <SocialItem social={"instagram"} href={"https://www.instagram.com/jahlon_pgn"}/>
                            <SocialItem social={"buymeacoffie"} href={"https://www.buymeacoffee.com/paradoxgaming"}/>
                            <SocialItem social={"springmerch"} href={"https://paradox-gaming-network.creator-spring.com/"}/>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} className={"footer_right"}>
                        <FooterLinks>
                            <a href="mailto:support@paradoxgaming.net">CONTACT US</a>
                        </FooterLinks>
                    </Col>
                </Row>
            </footer>
        </>

    )
}
