//node modules

//components


//Props settings for SosialItem


import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function SocialItem ({href, social, ...props}) {

    let SocialIcon;
    switch (social) {
        case "buymeacoffie":
            SocialIcon = <img
                style={{width: "26px", height: "26px"}}
                src="/media/images/icons/buymeacoffee.png" alt="buymeacoffie page"/>
            break;
        case "springmerch":
            SocialIcon = <img
                style={{width: "26px", height: "26px"}}
                src="/media/images/icons/springmerch.png" alt="Spring Merch store"/>
            break;
        default:
            SocialIcon = <FontAwesomeIcon icon={["fab", `fa-${social}`]}/>;
            break;
    }

    return (
        <>
            <a className={"social-item"} target={"_blank"} rel={"noreferrer nofollow"} href={href} {...props}>
                {SocialIcon}
                {props.children}
            </a>
        </>
    )
}
