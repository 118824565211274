//import styles
import './styles/styles.scss'

//importing React components
import {AppStateProvider} from "./context/state.jsx";
import {AppDataProvider} from "./context/data.jsx";
import {BrowserRouter} from "react-router-dom";
import App from "./App.jsx";
import ReactDOM from 'react-dom/client';
import HttpsRedirect from 'react-https-redirect';

//font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fab, fas);


//here only stuff inside <Helmet>...</Helmet> is editable, as this is the routing for the page



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <HttpsRedirect disabled={true}>
            <AppStateProvider>
                <AppDataProvider>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </AppDataProvider>
            </AppStateProvider>
        </HttpsRedirect>
    </>
)
