//Importing Components
import {Link, NavLink} from 'react-router-dom';
import {useRef, useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useNavFunctions from "./functions/useNavFunctions.jsx";


//import media

//importing Media


//----------------[Navigation Components]---------------------//

export default function Nav() {
    const [showMenu, setShowMenu] = useState(false);
    const navRef = useRef();
    const {RenderRoutes, pageScrolled, isMobile} = useNavFunctions(navRef, setShowMenu);




    //content to render on page
    return (
        <>
            <nav ref={navRef} className={`${isMobile ? "nav--mobile" : "nav"} ${pageScrolled ? "scrolled" : ""}`}>
                <div className={`nav_container`}>
                    <Link className={`jumpToContent`} to={`#pageContent`}>Jump to content</Link>
                    <Row className={"nav_navbar"}>
                        <Button title={"Navigation Toggle"} className={"nav-hamburger"} color={"default"} onClick={() => setShowMenu((old) => !old)}>
                            <FontAwesomeIcon icon={["fas", "bars"]}/>
                        </Button>
                        <Col className={"nav_left"}>
                            <NavLink className={"nav-item"} to={"/"}>Jahlon | Paradox Gaming Network</NavLink>
                        </Col>
                        <Col className={"nav_links nav_right" + (showMenu ? " open" : "")}>
                            <RenderRoutes/>
                        </Col>
                    </Row>
                </div>
            </nav>
        </>
    )
}
