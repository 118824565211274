//import Components
import {Suspense} from "react"
import Layout from "./layouts/MainLayout.jsx";
import LoadingScreen from "./modules/Loadingscreen/Loadingscreen.jsx"
import {Route, Routes} from "react-router-dom";
import {routes} from "./Routes.jsx";
import {v4} from "uuid";


//--------------------------[!Note]----------------------------------//
//this page should never be needed to be updated unless adding pages (ask Zettrex)
//if you want to add content like SEO or meta content add in HELMET(works like <head></head>) element just like good old HTML

//this is the pages static keywords, meaning what should be on all pages
export const staticMetaKeyword = `Jahlon, PGN Music, Paradox Gaming Network Music, Jahlon Music, DMCA Free Music, No Copyright Sound, No Copyright Music, Copyright Free Music, Free to Use Music, Copyright Free Music for Streamers, pgnmusic.com, free music, twitch, youtube, spotify, imusic, soundcloud, music, songs, community, `


/*React app component*/
export default function App () {
    //This gets the data for the webpage and adds them to a variable to be used to fill the page

    function LoadRoutes() {
        return routes.reduce((renders, route) => {
            if (!route.external) {
                return renders.concat(getRoute(route));
            }
            return renders;
        }, []);
    }

    function getRoute(route) {
        let renders = [];
        if (route.subMenu && route.subMenu.length > 0) {
            if (route.link && route.elem) {
                renders.push(<Route key={v4()} path={route.link} element={route.elem}/>)
            }
            renders.push(route.subMenu.map((subRoute) => {
                return getRoute(subRoute);
            }))
        } else if (route.link && route.elem) {
            renders.push(<Route key={v4()} path={route.link} element={route.elem}/>)
        }
        return renders.flat(5);
    }


    /*Makes sure we have data before we load the page*/


    //-----------[HTML]----------------//
    //content to render on page
    return (
        <>
            <div className="App">
                <Layout>
                    <Suspense fallback={<LoadingScreen/>}>
                        <Routes>
                            {LoadRoutes()}
                        </Routes>
                    </Suspense>
                </Layout>
            </div>
        </>
    )

}
