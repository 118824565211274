//import Components
import Nav from "../modules/Navigation/Navigation.jsx";
import Footer from "../modules/Footer/Footer.jsx";
import ReactPlayer from "react-player";


//function to set position of to top button





//----------------[App - Layout]---------------------//

export default function Layout({...props}) {
    //-----------[HTML]----------------//
    //content to render on page
    return (
        <>
            <Nav/>
            <div className={`page`}>
                <div className={"pageBackground"}>
                    <ReactPlayer
                        muted={true}
                        playsinline={true}
                        width={"inherit"}
                        height={"inherit"}
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "fixed",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            zIndex: -4,
                        }}
                        playing={true}
                        controls={false}
                        loop={true}
                        url={"/media/images/background/F1.mp4"}/>
                </div>
                <div id={`pageContent`} className={`page_container`}>
                    {props.children}
                </div>
            </div>
            <Footer/>
        </>
    )
}
