import {useEffect, useState} from "react";
import {routes} from "../../../Routes.jsx";
import {v4} from "uuid";
import {NavLink} from "react-router-dom";

export default function useNavFunctions(navRef, setShow) {
    const [pageScrolled] = useState(false);
    const [isMobile, setIsMobile] = useState(true);


    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1060) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
                setShow(false);
            }
        }
        function handleScroll() {
            if (window.scrollY >= 80 && navRef.current) {
                navRef.current.style.position = "fixed";
                navRef.current.style.top = 0;
            } else {
                navRef.current.style.position = "absolute";
                navRef.current.style.top = "80px";
            }
        }
        handleScroll();
        handleResize();

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.addEventListener("resize", handleResize);
        }
    }, [navRef, setShow]);



    function RenderRoutes() {
        const routeLinks = routes.reduce((routes, route) => {
            return routes.concat(getRoute(route));
        }, []);

        if (isMobile) {
            const mobileLinks = [
                <NavLink className={"nav-item"} to={"/"}>Home</NavLink>
            ]
            return mobileLinks.concat(routeLinks);
        } else {
            return routeLinks;
        }



        function getRoute(route) {
            let routes = [];
            //gets all links that has showInNav and creates nav element of them
            if (route.showInNav) {
                if (route.subMenu) {
                    if (route.link) {
                        routes.push(
                            <div key={v4()} style={{cursor: "default"}} className={"nav-group"}>
                                <NavLink to={route.link} className={"nav-item"}>{route.label}</NavLink>
                                <div className={"nav-submenu"}>
                                    {routes.concat(route.subMenu.map((route) => {
                                        return getRoute(route);
                                    }))}
                                </div>
                            </div>
                        )
                    } else {
                        routes.push(
                            <div key={v4()} style={{cursor: "default"}} className={"nav-item"}>
                                <div>{route.label}</div>
                                <div className={"nav-submenu"}>
                                    {routes.concat(route.subMenu.map((route) => {
                                        return getRoute(route);
                                    }))}
                                </div>
                            </div>
                        )
                    }
                } else {
                    if (route.external) {
                        routes.push(
                            <a
                                target={"_blank"}
                                rel={"noreferrer nofollow"}
                                key={v4()}
                                className={"nav-item"}
                                href={route.link}
                            >
                                {route.label}
                            </a>
                        )
                    } else {
                        routes.push(
                            <NavLink
                                key={v4()}
                                className={"nav-item"}
                                to={route.link}
                            >
                                {route.label}
                            </NavLink>
                        )
                    }
                }
            }
            return routes;
        }
    }


    return {RenderRoutes, pageScrolled, isMobile}
}
